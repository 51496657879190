import React from "react"
import CareerDetail from "../../../components/CareerDetail"
import Layout from "../../../components/Layout"
export default function SinglePosition(req, res) {
  const { id } = req.params
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/careers/positionsData.json") // Path relative to the 'static' folder
        const result = await response.json()
        const filteredData = result.filter(item => item.id == id)
        setData(filteredData[0])
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  if (!data) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        role="status"
      >
        Loading...
      </div>
    )
  }

  return (
    <Layout>
      <CareerDetail details={data} />
    </Layout>
  )
}
