import React from "react"
import { Form } from "react-bootstrap"

export default function UploadAttachment({
  label,
  onChange,
  value,
  Icon,
  required,
}) {
  const id = `upload-file-${Math.floor(Math.random() * 10)}`

  return (
    <Form.Group controlId={id} className="mb-3 position-relative">
      <Form.Label
        className={`d-flex align-items-center justify-content-between `}
        style={{ paddingBottom: value?.name ? "0" : "23px", fontSize: "14px" }}
      >
        <span>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </span>
        <span className="icon-container">
          <Icon />
        </span>
      </Form.Label>
      <Form.Control
        type="file"
        accept=".pdf"
        onChange={e => onChange(e.target.files[0])}
        required={required}
        className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
      />
      <div className="file-name">
        <Form.Text>{value?.name}</Form.Text>
      </div>
      <style jsx>{`
        .icon-container {
          margin-left: auto;
        }
        .file-name {
          border-bottom: 2px solid #e6e6e6;
          padding-bottom: 8px;
          margin-top: -8px;
        }
      `}</style>
    </Form.Group>
  )
}

UploadAttachment.defaultProps = {
  label: "Upload Attachment",
  onChange: file => {},
  value: null,
  Icon: () => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5L12.3536 4.64645L12 4.29289L11.6464 4.64645L12 5ZM11.5 17C11.5 17.2761 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.2761 12.5 17L11.5 17ZM18.3536 10.6464L12.3536 4.64645L11.6464 5.35355L17.6464 11.3536L18.3536 10.6464ZM11.6464 4.64645L5.64645 10.6464L6.35355 11.3536L12.3536 5.35355L11.6464 4.64645ZM11.5 5L11.5 17L12.5 17L12.5 5L11.5 5Z"
        fill="#333333"
      />
      <path d="M5 21H19" stroke="#333333" />
    </svg>
  ),
  required: false,
}
