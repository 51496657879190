import React, { useEffect, useState } from "react"
import Button from "../../shared/Button"
import "./Footer.scss"
import Select from "./SelectHook"
import { navigate } from "gatsby"
import Loader from "../../utils/Loader"

import UploadAttachment from "./UploadAttachment"
import { fileToAttachmentFormat, sendMail } from "../../utils/helpers"

const helpOptions = [
  {
    id: 1,
    name: "SQA Engineer",
    value: "SQA Engineer",
    budget: false,
  },
  {
    id: 2,
    name: "Software Engineer (Python)",
    value: "Software Engineer (Python)",
    budget: false,
  },
  {
    id: 3,
    name: "Software Engineer (JavaScript)",
    value: "Software Engineer (JavaScript)",
    budget: false,
  },
  {
    id: 4,
    name: "BD Executive",
    value: "BD Executive",
    budget: false,
  },
  {
    id: 5,
    name: "Product Designer",
    value: "Product Designer",
    budget: false,
  },
  {
    id: 6,
    name: "UI/UX Designer",
    value: "UI/UX Designer",
    budget: false,
  },
  {
    id: 6,
    name: "Software Engineer (Java)",
    value: "Software Engineer (Java)",
    budget: false,
  },
]

export default function ContactForm({ position }) {
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [cv, setCV] = React.useState(null)
  const [isFailed, setIsFailed] = React.useState(false)
  const [clearSelectFields, setClearSelectFields] = React.useState(false) //1

  const [state, setState] = useState({
    name: "",
    email: "",
    linkedin: "",
    field: "",
    message: "",
  })
  useEffect(() => {
    setState({
      ...state,
      field: position,
    })
  }, [position])

  const onChangeHandler = e => {
    const value = e.target.value
    setState({
      ...state,
      [e.target.name]: value,
    })
    setIsFailed(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (state.name.length > 25) return
    setOpen(true)

    let cvToSend = await fileToAttachmentFormat(cv, state.name, "cv")
    let otherfile = await fileToAttachmentFormat(
      file,
      state.name,
      "additional-file"
    )

    const body = {
      to: "careers@codefulcrum.com", // Change to your recipient
      subject: `Job Application ${state.field ? `for ${state.field}` : ""} (${
        state.name
      })`,
      name: `Job Application: ${state.name}`,
      html: `
      <table style=" border: 1px solid; width: 100%; border-collapse: collapse; color:black; text-align:center">
      <thead>
        <tr>
        <th style=" border: 1px solid">Name</th>
        <th style=" border: 1px solid">Email</th>
        <th style=" border: 1px solid">Linkedin</th>
        <th style=" border: 1px solid">Field</th>
        <th style=" border: 1px solid">Message</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <th style=" border: 1px solid; font-weight:normal">${state.name}</th>
        <td style=" border: 1px solid">${state.email}</td>
        <td style=" border: 1px solid">${state.linkedin}</td>
        <td style=" border: 1px solid">${state.field ? state.field : ""}</td>
        <td style=" border: 1px solid">${state.message}</td>
        </tr>
      </tbody>
      </table> 
      `,
      attachments: [cvToSend, otherfile].filter(file => file !== null),
    }

    sendMail(body)
      .then(res => {
        if (res.status == 200) {
          navigate("/thank-you-careers")
        } else {
          setIsFailed(true)
        }
        setOpen(false)
      })
      .catch(err => {
        setIsFailed(true)
        setOpen(false)
      })
  }

  const errorText = <span style={{ color: "red" }}>Enter a valid name</span>

  const clearButton = () => {
    const form = document.forms["contact"]
    form.reset()
    setClearSelectFields(true)
    setIsFailed(false)
    setFile(null)
    setCV(null)
  }

  return (
    <>
      <Loader open={open} />
      <form name="contact" onSubmit={handleSubmit}>
        <div className="row" style={{ rowGap: "2.5rem" }}>
          <div className="col-md-6 col-sm-12 mb-3">
            <label className="custom-input-label">
              Name <span className="required-asterisk">*</span>
            </label>
            <input
              type="text"
              className="custom-input"
              name="name"
              required
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <label className="custom-input-label">
              Email <span className="required-asterisk">*</span>
            </label>
            <input
              type="email"
              className="custom-input"
              name="email"
              required
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <label className="custom-input-label">LinkedIn</label>
            <input
              type="text"
              className="custom-input"
              name="linkedin"
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-3 form-fields">
            <Select
              options={helpOptions}
              label="Field"
              name="field"
              value={state.field}
              onChange={onChangeHandler}
              clearSelectFields={clearSelectFields}
              resetSelectClearState={() => setClearSelectFields(false)}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-3 position-relative">
            <UploadAttachment
              label="Upload CV"
              value={cv}
              required
              onChange={file => setCV(file)}
            />
          </div>
          <div className="col-md-6 col-sm-12 mb-3">
            <UploadAttachment
              label="Additional File"
              value={file}
              onChange={file => setFile(file)}
            />
          </div>
          <div className="col-12 mb-3 form-fields">
            <textarea
              onChange={onChangeHandler}
              name="message"
              cols="30"
              rows="4"
              placeholder="Why would you like to join CodeFulcrum?"
            ></textarea>
          </div>
        </div>
        {isFailed && (
          <div className="alert alert-danger text-start" role="alert">
            Message sending failed
          </div>
        )}
        <div className="text-end pt-5">
          <Button
            text="CANCEL"
            variant="fill"
            border="#2f2f2f"
            bgClr="#ffffff"
            clr="#2f2f2f"
            className="me-3"
            onClick={clearButton}
            type="button"
          />
          <Button
            text="SEND MESSAGE"
            variant="fill"
            bgClr="#f23b48"
            type="submit"
          />
        </div>
      </form>
    </>
  )
}
